/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Formik } from 'formik';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './contact-us.scss';

const ContactUs = () => {
  const fade = (index = 0) => useSpring({
    opacity: 1, delay: index * 200, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });

  return (
    <Layout extra={false} hasFooter={false}>
      <SEO title="Contact Us" />
      <div className="Contact-Us">
        <animated.div style={fade(0)} className="Contact-Us__Title">Let’s talk</animated.div>
        <animated.div style={fade(1)} className="Contact-Us__Des">Ready to move your idea forward? We&apos;d love to help</animated.div>
        <div className="Contact-Us__Content">
          <animated.div style={fade(2)} className="Contact-Us__Form">
            <Formik
              initialValues={{
                firstName: '', lastName: '', email: '', subject: '', message: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.firstName) {
                  errors.firstName = 'First name required';
                }
                if (!values.subject) {
                  errors.subject = 'Subject required';
                }
                if (!values.message) {
                  errors.message = 'Message required';
                }
                // if (!values.email) {
                //   errors.email = 'Email required';
                // } else if (
                //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                // ) {
                //   errors.email = 'Invalid email address';
                // }
                return errors;
              }}
              onSubmit={(values) => {
                const {
                  subject, message, firstName, lastName,
                } = values;
                const body = `Hi Launchdeck Team, %0D%0A%0D%0A I am ${firstName} ${lastName} %0D%0A%0D%0A ${message}`;
                const mailToLink = `mailto:team@launchdeck.org?subject=${subject}&body=${body}`;
                window.location.href = mailToLink;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              /* and other goodies */
              }) => (
                <form className="Contact-Us__Form" onSubmit={handleSubmit}>
                  <div className="H4BlackLeft">Drop a message to us</div>
                  <div className="Contact-Us__Form__Name">
                    <div>
                      <input name="firstName" placeholder="First name" onChange={handleChange} value={values.firstName} />
                      {errors.firstName && touched.firstName && <div className="Error-Message">{errors.firstName}</div>}
                    </div>
                    <div>
                      <input name="lastName" placeholder="Last name" onChange={handleChange} value={values.lastName} />
                    </div>
                  </div>

                  <input name="subject" placeholder="Subject" onChange={handleChange} value={values.subject} />
                  {errors.subject && touched.subject && <div className="Error-Message">{errors.subject}</div>}

                  <textarea name="message" placeholder="Describe your plan" rows={5} onChange={handleChange} value={values.message} />
                  {errors.message && touched.message && <div className="Error-Message">{errors.message}</div>}
                  <button type="submit" className="Contact-Us__Form__Btn" disabled={isSubmitting}>
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </animated.div>
          <animated.div style={fade(3)} className="Contact-Us__Info">
            <div className="Contact-Us__Info__Name">Launch Deck Contact</div>
            <div className="Contact-Us__Info__Details">
              105 Iowa Avenue #231
              <br />
              Iowa City Iowa, 52240
              <br />
              <br />
              <a href="tel:805-203-0422" style={{ textDecoration: 'none' }} className="Contact-Us__Info__Details"> 805-203-0422</a>

              <br />
              <a href="mailto:team@launchdeck.org" style={{ textDecoration: 'none' }} className="Contact-Us__Info__Details"> team@launchdeck.org</a>

            </div>
          </animated.div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
